import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import '../styles/header.css'
import $ from 'jquery' // important: case sensitive.
import { GrClose } from "@react-icons/all-files/gr/GrClose";
import { GrLinkNext } from "@react-icons/all-files/gr/GrLinkNext";
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import receita1 from "../images/receita1.jpg" // Tell webpack this JS file uses this image
import receita2 from "../images/PicanhaGrelhada2.jpg" // Tell webpack this JS file uses this image
import receita3 from "../images/receita3.jpg" // Tell webpack this JS file uses this image
import bifetira from "../images/bifetira.jpg" // Tell webpack this JS file uses this image
import chorizo from "../images/chorizo.jpg" // Tell webpack this JS file uses this image
import hamb from "../images/hamb.jpg" // Tell webpack this JS file uses this image
import logo from "../images/logo.png" // Tell webpack this JS file uses this image

import menu from "../images/menu.png" // Tell webpack this JS file uses this image
if (typeof window !== `undefined`) {

var desktopquery = window.matchMedia('(min-width: 992px)');
var mobilequery = window.matchMedia('(max-width: 991px)');
}

function clickMenu(){
  if (desktopquery.matches){

  $('#menu-section').animate({
    left: '50%'
});
  };
  if (mobilequery.matches){
    $('#menu-section').animate({
      left: '0'
  });
};
}

function closeMenu(){
  if (desktopquery.matches){

  $('#menu-section').animate({
    left: '100%'
});
  };
  if (mobilequery.matches){
    $('#menu-section').animate({
      left: '100%'
  });
};

}



const Header = () => {
  return (
    <div>
        <div id="menu-section">
            <div className="wrapper">
              <div className="header">
                <div className="title old-press">
                As nossas sugestões
                </div>
                <div className="close-icon" onClick={closeMenu}>
                <GrClose/>
                </div>
                
              </div>
              <div className="conteudo">
              <Link to="/naco-chimichurri/">
                <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={receita1} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Naco com Molho Chimichurri
                    </div>
                    {/* <div className="desc sneak-regular">
                    O naco com molho de chimichurri leva-o por uma viagem aos paladares tipicamente argentinos. 
                    </div> */}
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
              <Link to="/picanha-grelhada/">
              <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={receita2} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Picanha Grelhada com Vinagrete
                    </div>
                    {/* <div className="desc sneak-regular">
                    A acidez do vinagrete é o sabor ideal para combinar com a carne tenra e suculenta da picanha grelhada.
                    </div> */}
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
              <Link to="/bife-portuguesa/">

              <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={receita3} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Bife à Portuguesa
                    </div>
                    {/* <div className="desc sneak-regular">
                    Com origem na América do Sul, a carne tenra e saborosa, conquista a gastronomia portuguesa e seduz-nos através de uma experiência típica e tradicional.
                    </div> */}
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
              <Link to="/bife-tira/">

<div className="receita1 card">
    <div className="contentor">
    <div className="foto">
    <img src={bifetira} alt="Logo" />

    </div>
    <div className="elements">
      <div className="titulo absara">
        Bife de Tira com Arroz e Feijão
      </div>
      {/* <div className="desc sneak-regular">
      Feijão preto típico da gastronomia carioca é a guarnição mais desejável para o bife de tira de qualidade superior.
      </div> */}
     
    </div>
    <div className="icon">
        <GrLinkNext />
      </div>
      </div>
</div>
</Link>

<Link to="/hamburguer/">

<div className="receita1 card">
    <div className="contentor">
    <div className="foto">
    <img src={hamb} alt="Logo" />

    </div>
    <div className="elements">
      <div className="titulo absara">
        Hambúrguer com cogumelos salteados
      </div>
      {/* <div className="desc sneak-regular">
      Cada camada deste hambúrguer recheado de sabor, envolve-o numa experiência gastronómica latina.
      </div> */}
     
    </div>
    <div className="icon">
        <GrLinkNext />
      </div>
      </div>
</div>
</Link>
<Link to="/bife-chorizo/">

<div className="receita1 card">
    <div className="contentor">
    <div className="foto">
    <img src={chorizo} alt="Logo" />

    </div>
    <div className="elements">
      <div className="titulo absara">
        Bife do Chorizo com polenta grelhada
      </div>
      {/* <div className="desc sneak-regular">
      O tenro Bife de Chorizo aliado à crocância da polenta grelhada são a conjugação de texturas mais apetecível.
      </div> */}
     
    </div>
    <div className="icon">
        <GrLinkNext />
      </div>
      </div>
</div>
</Link>
              </div>

          </div>
        </div>
      <Headroom >
      <div id="barra-nav">
        <div className="nav-container">
          <div className="brand">
            <Link to="/" title="Homepage"><img src={logo} alt="Logo" /></Link>
          </div>
          <div className="menu">
          <img onClick={clickMenu} src={menu} alt="Logo" />

          </div>
          
    
        
       
   
       
     
  
  </div></div>

    </Headroom>
  
    </div>
    
  )

}
export default Header